.hero-nav__link span:before, .view:before {
  content: "";
  position: absolute;
}

@font-face {
  font-family: Fira Sans;
  font-weight: 400;
  font-style: normal;
  src: url("FiraSans-Regular.95bc810b.eot");
  src: url("FiraSans-Regular.f7fcc28b.woff2") format("woff2");
}

@font-face {
  font-family: Fira Sans;
  font-weight: 700;
  font-style: normal;
  src: url("FiraSans-Bold.6210eb8a.eot");
  src: url("FiraSans-Bold.f12c8e87.woff2") format("woff2");
}

:root {
  --font-main: "Fira Sans";
  --font-size: 1em;
  --color-text-primary: #000;
  --color-accent-primary: #9b6109;
  --color-accent-secondary: #fdf4e4;
}

body, html {
  height: 100%;
  font-family: var(--main-font);
}

p {
  margin-top: 0;
  margin-bottom: 15px;
}

path {
  transition: all .35s;
}

body {
  font-size: var(--font-size);
  min-width: 320px;
  line-height: 1.54;
  font-family: var(--font-main);
  color: var(--color-text-primary);
  margin: 0;
  position: relative;
}

::placeholder {
  color: #666;
  opacity: 1;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
  display: block;
}

.image img {
  width: 100%;
  display: block;
}

li, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button, input, select, textarea {
  border: none;
  outline: 0;
  margin: 0;
  padding: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
  transition: all .3s linear;
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
  background: none;
}

select {
  appearance: none;
}

textarea {
  resize: none;
}

body.hidden {
  overflow: hidden;
}

.header {
  padding: 25px 0;
}

.header-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.main-list {
  align-items: center;
  display: flex;
}

.main-list li + li {
  margin-left: 25px;
}

.main-list a {
  display: inline-block;
  position: relative;
}

.hamburger {
  cursor: pointer;
  display: none;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: #333;
}

.button {
  color: currentColor;
  text-align: center;
  border: 1px solid var(--color-accent-primary);
  border-radius: .25em;
  width: 100%;
  max-width: 13.5em;
  padding: .9375em .6875em;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.button--primary {
  color: var(--color-accent-secondary);
  background-color: var(--color-accent-primary);
}

.button--secondary {
  color: var(--color-accent-primary);
  background-color: var(--color-accent-secondary);
}

.button--primary:hover {
  background: #744907;
}

.button--secondary:hover {
  background: #ffe5b9;
}

.h1 {
  margin-bottom: 42px;
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
}

.h2 {
  margin-bottom: 16px;
  font-size: 2.25em;
  font-weight: 700;
  line-height: normal;
}

.h3 {
  font-size: 24px;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 76px;
}

.hero {
  padding-top: 113px;
  padding-bottom: 104px;
}

.hero-header {
  display: none;
}

.hero-icon {
  margin-bottom: 32px;
  display: block;
}

.hero-icon img {
  display: block;
}

.hero-row {
  display: flex;
}

.hero-item {
  width: 50%;
}

.hero-item:last-of-type, .work-item:last-of-type {
  padding-left: 2em;
}

.hero-img {
  position: relative;
}

.hero-img__mobile {
  display: none;
}

.hero-img__overlay {
  content: "";
  z-index: 1;
  object-fit: cover;
  width: 86.63%;
  height: 85.37%;
  position: absolute;
  top: 4%;
  left: 3%;
  overflow: visible;
}

.hero-img__phone {
  position: absolute;
  right: 32px;
}

.hero-img__circle {
  z-index: 1;
  position: absolute;
  top: -16px;
  left: 6px;
}

.hero-img__circle2 {
  z-index: -1;
  position: absolute;
  bottom: -23px;
  right: -54px;
}

.hero-img__circle3 {
  display: none;
}

.hero-info, .work-info {
  max-width: 32.75em;
  margin-left: auto;
  position: relative;
}

.hero-info {
  margin-top: 4.75em;
}

.hero-info .view-nav {
  margin-bottom: 41px;
}

.hero-nav {
  align-items: center;
  display: flex;
}

.hero-nav > span {
  margin: 0 11px;
}

.hero-nav__link {
  color: #9b6109;
  align-items: center;
  gap: 7px;
  font-weight: 700;
  display: flex;
}

.hero-nav__link span {
  position: relative;
}

.hero-nav__link span:before {
  background: #9b6109;
  width: 100%;
  height: 1px;
  transition: all .35s;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

.hero-nav__link:hover span:before {
  transform: scale(1);
}

.feature {
  margin-bottom: 7.375em;
}

.feature-benefit {
  display: none;
}

.feature .container {
  z-index: 1;
  position: relative;
}

.feature-circle {
  z-index: -1;
  margin-left: -75px;
  position: absolute;
  top: -117px;
  left: 50%;
}

.feature-row {
  display: flex;
}

.feature-item {
  width: 50%;
}

.feature-list__icon {
  background: #fff4e2;
  border: 1px solid #fadbae;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.feature-list {
  counter-reset: feature-list-item-counter;
}

.feature-list li {
  counter-increment: card-list-item-counter;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  line-height: 120%;
  display: flex;
}

.feature-list__icon--count:before {
  content: counter(card-list-item-counter);
  color: var(--color-accent-primary);
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feature-list li b {
  font-weight: 700;
}

.feature-list li + li {
  margin-top: 16px;
}

.feature-list--top .feature-list__icon {
  position: relative;
}

.feature-list--top .none img, .feature-list--top .none svg {
  top: 0;
}

.feature-list--custom li {
  align-items: flex-start;
}

.feature-info {
  margin-top: 224px;
  display: flex;
}

.feature-info__item {
  width: 50%;
}

.feature-info__item:last-child {
  padding-left: 7px;
}

.feature-img img {
  z-index: 2;
  width: 470px;
  margin: 0 auto;
  display: block;
  position: relative;
  left: 6px;
}

.work-list, .work-item__ellips {
  display: none;
}

.work-row {
  align-items: center;
  display: flex;
}

.work-item {
  width: 50%;
  position: relative;
}

.work-img {
  max-width: 635px;
  position: relative;
}

.work-img__bg--mobile {
  display: none;
}

.work-img__bg {
  margin: 0 auto;
  display: block;
}

.work-img__circle {
  z-index: -1;
  position: absolute;
  bottom: -32px;
  right: -81px;
}

.work-img__circle2 {
  display: none;
}

.view {
  z-index: 1;
  margin-bottom: 40px;
  position: relative;
}

.view-circle2 {
  display: none;
}

.view-circle {
  z-index: -2;
  margin-left: -89px;
  position: absolute;
  bottom: 57.5px;
  left: 50%;
}

.view:before {
  background: #fdf4e4;
  border-top: 1px solid #9b6109;
  width: 100%;
  height: 281px;
  top: 136px;
  left: 0;
}

.view-row {
  justify-content: center;
  align-items: center;
  margin-top: 7.375em;
  display: flex;
  position: relative;
}

.view-img {
  padding-right: 46px;
}

.view-img img {
  margin-left: auto;
  display: block;
}

.view-nav {
  align-items: center;
  gap: 24px;
  display: flex;
}

.view-head {
  color: #9b6109;
  margin-bottom: 18px;
  font-size: 32px;
  font-weight: 700;
}

.view-content {
  padding-left: 12px;
  position: relative;
  top: -14px;
}

.view-content, .view-img {
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;
  position: relative;
}

.contact {
  margin-bottom: 103px;
}

.contact-icon {
  margin-bottom: 54px;
  display: block;
}

.contact-icon img {
  margin: 0 auto;
  display: block;
}

.contact-nav {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inner .feature-info {
  margin-top: 109px;
}

.inner .feature-circle {
  top: -111px;
}

.inner .hero {
  padding-bottom: 98px;
}

@media only screen and (width <= 1300px) {
  .feature-circle {
    width: 26vw;
  }

  .work-img__circle {
    width: 46vw;
  }

  .hero-img {
    left: 0;
  }

  .hero-img__phone {
    width: 20vw;
  }

  .hero-img__circle2 {
    width: 45vw;
  }

  .hero-img__circle {
    width: 11.5vw;
  }

  .work-img__circle {
    bottom: -20px;
    right: -30px;
  }

  .hero-img__circle2 {
    bottom: -13px;
    right: -24px;
  }
}

@media screen and (width >= 768px) {
  .feature-list li span:not(:last-of-type) {
    max-width: 248px;
  }
}

@media only screen and (width <= 1023px) {
  .inner .feature {
    margin-bottom: 65px;
  }

  .h1 {
    margin-bottom: 24px;
    font-size: 36px;
  }

  .hero {
    padding-top: 70px;
  }

  .hero-icon {
    margin-bottom: 16px;
  }

  .hero-img__circle2 {
    right: -16px;
  }

  .hero-item, .work-item {
    padding: 0 15px;
  }

  .hero-info {
    margin-top: 0;
  }

  .hero-info .view-nav {
    margin-bottom: 24px;
  }

  .feature {
    margin-bottom: 60px;
  }

  .feature-list li {
    font-size: 20px;
  }

  .feature-item:first-child {
    width: 60%;
  }

  .feature-item:last-child {
    width: 40%;
  }

  .feature-info {
    margin-top: 130px;
  }

  .feature-circle {
    margin-left: -25px;
    top: -77px;
  }

  .work-item {
    padding: 0 15px;
  }

  .work-img__circle {
    right: -15px;
  }

  .view-row {
    left: 0;
  }

  .view-img {
    padding-right: 30px;
  }
}

@media only screen and (width <= 767px) {
  .container {
    padding-inline: 27px;
  }

  .inner .work {
    z-index: 0;
    margin-top: -65px;
  }

  .inner .hero {
    padding-bottom: 53px;
  }

  .inner .hero-info .view-nav {
    margin-bottom: 32px;
  }

  .inner .feature {
    margin-bottom: 0;
  }

  .inner .feature-benefit {
    top: -9px;
  }

  .inner .feature-img img {
    width: 397px;
    left: auto;
  }

  .inner .feature-item:first-child {
    margin-bottom: 87px;
  }

  .inner .feature-info {
    margin-top: 0;
  }

  .hero {
    padding-top: 33px;
    padding-bottom: 45px;
    overflow: hidden;
  }

  .hero-icon {
    align-self: start;
    padding-top: 13px;
  }

  .hero-row {
    display: block;
  }

  .hero-item, .work-item {
    width: 100%;
    padding: 0;
  }

  .hero-item:last-of-type, .work-item:last-of-type {
    padding-left: 0;
  }

  .hero-img, .work-img {
    max-width: initial;
    margin-inline: -27px;
  }

  .hero-header {
    justify-content: center;
    align-items: center;
    margin-bottom: 29px;
    display: flex;
    position: relative;
    left: 9px;
  }

  .hero-header .hero-icon {
    margin: 0 31px 0 0;
  }

  .hero-header .h1 {
    margin: 0;
  }

  .hero-nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero-img__bg, .hero-img__overlay {
    display: none;
  }

  .hero-img__mobile {
    width: 100%;
    margin-bottom: 39px;
    display: block;
  }

  .hero-img__circle, .hero-img__circle2 {
    display: none;
  }

  .hero-info {
    max-width: 100%;
    margin: 0;
    left: 0;
  }

  .hero-info .h1, .hero-info .hero-icon {
    display: none;
  }

  .hero-info .view-nav {
    margin-bottom: 40px;
  }

  .view {
    margin-bottom: 147px;
  }

  .view:before {
    top: 50%;
    transform: translateY(-50%);
  }

  .view-circle {
    display: none;
  }

  .view-circle2 {
    z-index: -2;
    display: block;
    position: absolute;
    bottom: -71px;
    left: 0;
  }

  .view-row {
    margin-top: 6.9375em;
    left: -38px;
  }

  .view-head {
    max-width: 150px;
    margin-bottom: 22px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.25;
  }

  .view-nav {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .view-img {
    width: 170px;
    margin-right: 26px;
    padding-right: 0;
  }

  .view-img img {
    width: 100%;
    max-width: none;
  }

  .view-content {
    text-align: center;
    width: 216px;
    padding-left: 0;
    top: -10px;
  }

  .contact {
    margin-bottom: 64px;
  }

  .contact-icon {
    display: none;
  }

  .feature {
    margin-bottom: 0;
    overflow: hidden;
  }

  .feature-benefit {
    display: block;
    position: absolute;
    top: -12px;
    left: -16px;
  }

  .feature-img img {
    width: 407px;
    max-width: none;
    left: auto;
    right: -75px;
  }

  .feature-list li {
    font-size: 24px;
  }

  .feature-circle {
    display: none;
  }

  .feature-info {
    margin-top: 0;
  }

  .feature-info__item {
    width: 100%;
    padding-left: 12px;
  }

  .feature-info {
    flex-direction: column;
  }

  .feature-info__item:last-child {
    margin-top: 6.5em;
  }

  .feature-row {
    display: block;
  }

  .feature-item {
    position: relative;
  }

  .feature-item:first-child {
    width: 100%;
    margin-bottom: 72px;
  }

  .feature-item:last-child {
    width: 100%;
  }

  .work {
    z-index: 1;
    margin-bottom: 0;
    padding-top: 91px;
    position: relative;
    overflow: hidden;
  }

  .work-list {
    padding-left: 12px !important;
    display: block !important;
  }

  .work-item__ellips {
    object-fit: cover;
    width: 100%;
    height: calc(100% + 4.0625em);
    max-width: initial;
    display: block;
    position: absolute;
    top: -4.5em;
    left: -4.1875em;
    overflow: visible;
  }

  .work-row {
    flex-direction: column;
  }

  .work-item:last-child {
    order: -1;
    margin-bottom: 1.6875em;
  }

  .work-info {
    margin-bottom: 30px;
    margin-left: 0;
  }

  .work-img__bg {
    display: none;
  }

  .work-img__bg--mobile {
    width: 100%;
    display: block;
  }

  .work-img__circle {
    display: none;
  }
}

@media only screen and (width <= 374px) {
  .view-content {
    width: 175px;
  }

  .view-img {
    margin-right: 14px;
  }

  .feature-list--top img {
    top: -4px;
  }

  .feature-list li {
    font-size: 20px;
  }

  .hero-icon {
    position: static;
  }

  .hero-icon img {
    display: block;
  }
}

.footer {
  text-align: center;
  background: #9b6109;
  padding: 40px 0;
}

.copyright {
  margin-bottom: 24px;
}

.footer__txt {
  color: #fff;
  line-height: 1.3;
}

.footer__legal > a {
  text-decoration: underline;
  display: inline;
}

.footer__legal > a:last-child {
  margin-left: 24px;
}

.socials {
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  display: flex;
}

.socials__link {
  width: 36px;
  height: 36px;
}

.swiper {
  margin-top: 80px;
  position: relative;
}

.swiper--menu {
  margin-top: 6.5em;
}

.h2--swiper {
  text-align: center;
  margin-bottom: 30px;
}

.swiper__btn {
  background: #fff3df;
  border-radius: 100%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-top: 132px;
  transition: background .35s;
  display: none;
}

.swiper__btn:disabled, .swiper__btn:disabled:hover {
  cursor: auto;
  background: #faf6ee;
}

.swiper__btn:focus, .swiper__btn:hover {
  background: #ffe5b9;
}

.swiper__btn:last-of-type > svg {
  transform: rotate(180deg);
}

.swiper__wrapper {
  justify-content: center;
  gap: 24px;
  display: flex;
}

.swiper__list {
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 16px;
  padding: 0 27px;
  display: flex;
  overflow-x: auto;
}

.swiper__list::-webkit-scrollbar {
  display: none;
}

.swiper__item {
  scroll-snap-align: center;
  flex: 0 0 97%;
}

.swiper__figure {
  border-radius: 16px;
  margin: 0;
  overflow: hidden;
}

.swiper__img {
  object-fit: cover;
  object-position: center;
  vertical-align: middle;
  width: 100%;
  height: 280px;
}

.swiper__caption {
  letter-spacing: 0;
  text-align: center;
  background: #fff4e2;
  height: 100%;
  min-height: 180px;
  padding: 32px 22px 0;
  font-family: Fira Sans;
  font-size: 24px;
  line-height: 29px;
}

.swiper__title {
  letter-spacing: 0;
  font-weight: 700;
}

.swiper__desc {
  margin: 8px 0 0;
  font-weight: 400;
}

@media screen and (width >= 768px) {
  .swiper__wrapper {
    padding: 0 27px;
  }

  .swiper__btn {
    display: inline-flex;
  }

  .swiper__wrapper {
    margin: auto;
  }

  .swiper__list {
    padding: 0;
  }

  .swiper__item {
    scroll-snap-align: start;
    flex-basis: 100%;
  }
}

@media screen and (width >= 1024px) {
  .swiper {
    margin-top: 100px;
  }

  .swiper--menu {
    margin-top: 9.375em;
  }

  .swiper__wrapper {
    max-width: 1440px;
    padding: 0 76px;
  }

  .swiper__item {
    scroll-snap-align: start;
    flex-basis: calc(50% - 8px);
  }
}

@media screen and (width >= 1300px) {
  .swiper__item {
    flex-basis: calc(33.3333% - 10.6667px);
  }
}
/*# sourceMappingURL=index.578d76e6.css.map */
